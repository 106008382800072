

.ant-tooltip-inner {
    color: rgb(22, 22, 21);
    background-color: white;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    border-bottom-color: black;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow:before {
    background-color: black;
}

.auth-bg {
  background-image: linear-gradient(to right, #43cea2 , #185a9d);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

